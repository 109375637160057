import React from "react";
import { Boxs, Group } from "saltui";
import {
    ICustomerForListDTO,
    ICustomerGradeDTO,
    ICustomerTypeDTO
} from "../../app/WebAPIClients";
import Consts from "../Consts";
import CustomerUtil from "../CustomerUtil";
import ESIcon from "../ESIcon";
const { HBox, Box } = Boxs;

declare interface ICustomerListProps extends React.Props<CustomerList> {
    customers: ICustomerForListDTO[];
    showCreateItemIcon: boolean;
    allowSelection?: boolean;
    selectedCustomer?: ICustomerForListDTO;
    onCreateItemClick: () => void;
    onItemClick: (customer: ICustomerForListDTO) => void;
    onSelectionChange?: (customer: ICustomerForListDTO) => void;
}

declare interface ICustomerListState {
    customerTypes?: ICustomerTypeDTO[];
    customerGrades?: ICustomerGradeDTO[];
}

class CustomerList extends React.Component<
    ICustomerListProps,
    ICustomerListState
> {
    public static defaultProps: ICustomerListProps;
    constructor(props) {
        super(props);
        this.state = {};
    }

    public async componentDidMount() {
        const customerTypes = await Consts.CustomerTypes.getTypes();
        const customerGrades = await Consts.CustomerGrades.getGrades();
        this.setState({ customerGrades, customerTypes });
    }

    public render() {
        const s = this.state;
        const {
            customers,
            onCreateItemClick,
            onItemClick,
            onSelectionChange,
            selectedCustomer,
            showCreateItemIcon,
            allowSelection
        } = this.props;

        return (
            <Group className="project-tingle">
                <Group.Head className="t-group-head">
                    <HBox vAlign="center">
                        <Box flex={1}>客户列表</Box>
                        <Box>
                            {showCreateItemIcon ? (
                                <span onClick={onCreateItemClick}>
                                    <ESIcon
                                        name="icon-jiahao"
                                        fill="#1296db"
                                        height={25}
                                        width={25}
                                    />
                                </span>
                            ) : null}
                        </Box>
                    </HBox>
                </Group.Head>
                <Group.List lineIndent={15} itemIndent={[15, 15]}>
                    {customers && customers.length && customers.length > 0 ? (
                        customers.map(c => {
                            const selected =
                                c === selectedCustomer ? true : false;
                            return CustomerUtil.getCustomerListItem(
                                c,
                                s.customerTypes,
                                s.customerGrades,
                                onItemClick,
                                allowSelection,
                                onSelectionChange,
                                selected
                            );
                        })
                    ) : (
                        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                            没有数据
                        </div>
                    )}
                </Group.List>
            </Group>
        );
    }
}

CustomerList.defaultProps = {
    customers: null,
    showCreateItemIcon: true,
    allowSelection: false,
    onItemClick: customer => {},
    onCreateItemClick: () => {},
    onSelectionChange: customer => {}
};

export default CustomerList;
