import React from "react";
import { Boxs } from "saltui";
import Perm from "../../app/Perm";
import Consts from "../Consts";
import ListItem from "../ListItem";
import PermCtrl from "../PermCtrl";
import ReferencePriceInfo from "../ReferencePriceInfo";
import SalesOrderUtil from "../SalesOrderUtil";
import Settings from "../SettingInfo";
import SettingsDefinition from "../SettingsDefinition";
import StockingAreaUtil from "../StockingAreaUtil";
import StoneArtifactUtil from "../StoneArtifactUtil";
import { ISalesOrderDetailForUI, ReferencePriceSize } from "../type";
import Util from "../Util";
import { IBundleForListDTO, StoneArtifactType } from "../../app/WebAPIClients";
import BundleStatus from "../BundlesWithSamePrefixStatus/BundleStatus";

const { BlockStatus } = Consts;
const { HBox, Box } = Boxs;

declare interface ISalesOrderDetailListItemProps {
  details: ISalesOrderDetailForUI[];
  salesOrderDetail: ISalesOrderDetailForUI;
  orderType?: number;
  showPrice?: boolean;
  customShowReferencePrice?: boolean;
  allowDeletion?: boolean;
  allowEditing?: boolean;
  categoryList: IIdNameItem[];
  gradeList: IIdNameItem[];
  onClick?: (item) => void;
  onDelete?: (item: ISalesOrderDetailForUI) => void;
  onUnitPriceChange?: (item: ISalesOrderDetailForUI, priceTxt: string) => void;
  onDeductedQuantityChange?: (
    item: ISalesOrderDetailForUI,
    deductedQuantityTxt: string,
  ) => void;
  onPaymentIconClick?: (item: ISalesOrderDetailForUI) => void;
  onShipmentIconClick?: (item: ISalesOrderDetailForUI) => void;
  salesOrderId?: number; // 用于点击扎的参考价信息查看其价格组成
  customerId?: number; // 用于点击扎的参考价信息查看其价格组成
  showBundlesWithSamePrefixes?: boolean;
  bundlesWithSamePrefixes?: IBundleForListDTO[];
}

declare interface ISalesOrderDetailListItemState {
  enableReferencePrice: boolean;
  showBundleGrades: boolean;
}

class SalesOrderDetailListItem extends React.Component<
  ISalesOrderDetailListItemProps,
  ISalesOrderDetailListItemState
> {
  public static defaultProps: ISalesOrderDetailListItemProps;
  constructor(props) {
    super(props);
    this.state = {
      enableReferencePrice: Settings.getBoolValue(
        SettingsDefinition.EnableReferencePriceKey,
      ),
      showBundleGrades: PermCtrl.isAuthorized(Perm.SB_G_R),
    };
  }

  public render(): JSX.Element {
    const t = this;
    const s = t.state;

    const {
      details,
      salesOrderDetail,
      orderType,
      showPrice,
      allowDeletion,
      categoryList,
      gradeList,
      customShowReferencePrice,
      showBundlesWithSamePrefixes,
      bundlesWithSamePrefixes,
      ...other
    } = t.props;

    let { allowEditing } = t.props;

    const sodInfo = StoneArtifactUtil.getStoneArtifactInfoForList(
      salesOrderDetail,
      categoryList,
      gradeList,
      s.showBundleGrades,
    );
    let title = sodInfo.title;
    if (orderType === 80 && salesOrderDetail.block !== null) {
      title = (
        <span>
          {title + " | "}
          {t.getBlockStatus(salesOrderDetail.block)}
        </span>
      );
    }

    const showStockingArea =
      StoneArtifactUtil.getShowStockingArea(salesOrderDetail);
    const description = (
      <div>
        {sodInfo.text}
        {showStockingArea === true
          ? StockingAreaUtil.showStockingArea(
              showStockingArea,
              StockingAreaUtil.getStockingAreaId(salesOrderDetail),
            )
          : null}
      </div>
    );

    const unitPrice = SalesOrderUtil.getPriceInfo(salesOrderDetail);

    const readOnly =
      (orderType === 10 ||
        orderType === 30 ||
        orderType === 80 ||
        orderType === 40 ||
        orderType === 50) &&
      (salesOrderDetail.hasLoaded ||
        salesOrderDetail.hasShipped ||
        salesOrderDetail.addedToPO ||
        salesOrderDetail.paid);
    const isTilePackingCaseItem = salesOrderDetail.type === 30;

    let extraInfo = null;
    const icon = SalesOrderUtil.showIcon(
      salesOrderDetail,
      t.handlePaymentIconClick,
      t.handleShipmentIconClick,
    );
    if (isTilePackingCaseItem) {
      allowEditing = false; // 工程板销售明细不允许编辑
    }

    if (
      showPrice &&
      allowEditing &&
      ((s.enableReferencePrice && unitPrice !== null) ||
        !s.enableReferencePrice ||
        unitPrice === null)
    ) {
      extraInfo = (
        <HBox flex={1} className="unitPriceArea">
          <Box className="unitPriceLabel">单价</Box>
          <Box>
            <div>
              <input
                type="number"
                min="0"
                step="0.1"
                name="unitPrice"
                readOnly={readOnly}
                onChange={t.handleUnitPriceChange}
                className={
                  readOnly
                    ? "unitPriceTextBox readOnlyTextBox"
                    : "unitPriceTextBox"
                }
                value={salesOrderDetail.priceText}
              />
              {t.getReferencePriceInfo()}
            </div>
          </Box>
          <Box className="deductedSizeLabel">
            {!isTilePackingCaseItem ? "扣方" : null}
          </Box>
          <Box flex={1}>
            {!isTilePackingCaseItem ? (
              <input
                type="number"
                min="0"
                step="0.1"
                name="deductedQuantity"
                readOnly={readOnly}
                onChange={t.handleQuantityChange}
                className={
                  readOnly
                    ? "unitPriceTextBox deductedTextPosition readOnlyTextBox"
                    : "unitPriceTextBox deductedTextPosition"
                }
                value={salesOrderDetail.deductedQuantityText}
              />
            ) : null}
          </Box>
          <Box>
            <p className="demo3-t-list-title">
              <span className="unitPriceText">
                ￥
                {Util.calculateSubTotal(
                  salesOrderDetail.quantity,
                  salesOrderDetail.deductedQuantity,
                  salesOrderDetail.price,
                )}
              </span>
            </p>
          </Box>
        </HBox>
      );
    } else if (showPrice) {
      extraInfo = (
        <HBox className="unitPriceArea">
          <Box className="unitPriceLabel">单价</Box>
          <Box>
            <div>
              <span className="unitPriceText">￥{salesOrderDetail.price}</span>
              {t.getReferencePriceInfo()}
            </div>
          </Box>
          <Box className="deductedSizeLabel">
            {!isTilePackingCaseItem && salesOrderDetail.deductedQuantity > 0
              ? "扣方"
              : null}
          </Box>
          <Box>
            {!isTilePackingCaseItem && salesOrderDetail.deductedQuantity > 0 ? (
              <span className="unitPriceText deductedSizeText">
                {salesOrderDetail.deductedQuantity}
              </span>
            ) : null}
          </Box>
          <Box>
            <p className="demo3-t-list-title">
              <span className="unitPriceText subTotalStyl">
                ￥{salesOrderDetail.subTotal}
              </span>
            </p>
          </Box>
        </HBox>
      );
    }

    if (
      salesOrderDetail.type === StoneArtifactType.StoneBundle &&
      showBundlesWithSamePrefixes
    ) {
      const bundlesWithSamePrefixesComponent = (
        <BundleStatus
          currentBundle={salesOrderDetail.bundle}
          bundlesWithSamePrefixes={bundlesWithSamePrefixes}
          salesOrderDetails={details}
          showStatusInColor={true}
          showPrice={true}
        />
      );
      extraInfo =
        extraInfo === null ? (
          bundlesWithSamePrefixes
        ) : (
          <div>
            {bundlesWithSamePrefixesComponent}
            {extraInfo}
          </div>
        );
    }

    let avatarTitle =
      StoneArtifactUtil.getStoneArtifactAvatarText(salesOrderDetail);

    // 工程板的Avatar显示为工程板箱号
    if (salesOrderDetail.type === 30) {
      avatarTitle = salesOrderDetail.tilePackingCase.caseNumber;
    }

    return (
      <ListItem
        item={salesOrderDetail}
        avatarTitle={avatarTitle}
        title={title}
        description={description}
        extraInfo={extraInfo}
        icon={icon}
        allowDeletion={allowDeletion}
        onDelete={t.props.onDelete}
        onClick={t.props.onClick}
      />
    );
  }

  private getBlockStatus(s) {
    const blockStatusName = BlockStatus.getStatus(s.status).text;
    let className = "";
    switch (s.status) {
      case 5:
        className = "blockPurchased";
        break;
      case 10:
        className = "blockInStock";
        break;
      case 20:
        className = "blockManufacturing";
        break;
      case 30:
        className = "blockProcessed";
        break;
      case 40:
        className = "blockSold";
        break;
      case 50:
        className = "blockReserved";
        break;
      case 100:
        className = "blockDiscarded";
        break;
    }
    return <span className={className}>{blockStatusName}</span>;
  }

  private handleUnitPriceChange = (e) => {
    let priceTxt = e.target.value;
    // 单价只保留两位小数
    priceTxt = Util.toFixedTowDecimal(priceTxt);
    const { salesOrderDetail, onUnitPriceChange } = this.props;
    if (onUnitPriceChange) {
      onUnitPriceChange(salesOrderDetail, priceTxt);
    }

    if (e) {
      e.stopPropagation();
    }
  };

  private handleQuantityChange = (e) => {
    const deductedQuantityTxt = e.target.value;
    const { salesOrderDetail, onDeductedQuantityChange } = this.props;
    if (onDeductedQuantityChange) {
      onDeductedQuantityChange(salesOrderDetail, deductedQuantityTxt);
    }

    if (e) {
      e.stopPropagation();
    }
  };

  private handlePaymentIconClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    const t = this;
    const { salesOrderDetail, onPaymentIconClick } = t.props;
    if (onPaymentIconClick) {
      onPaymentIconClick(salesOrderDetail);
    }

    if (event) {
      event.stopPropagation();
    }
  };

  private handleShipmentIconClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    const t = this;
    const { salesOrderDetail, onShipmentIconClick } = t.props;
    if (onShipmentIconClick) {
      onShipmentIconClick(salesOrderDetail);
    }

    if (event) {
      event.stopPropagation();
    }
  };

  private getReferencePriceInfo = () => {
    const t = this;
    const s = t.state;
    const {
      salesOrderDetail,
      orderType,
      customShowReferencePrice,
      categoryList,
      gradeList,
      salesOrderId,
      customerId,
    } = t.props;

    const unitPrice = SalesOrderUtil.getPriceInfo(salesOrderDetail);
    const showReferencePrice =
      orderType !== 80 ||
      (orderType === 80 &&
        !Util.isDefinedAndNotNull(salesOrderDetail.bundleId));
    return s.enableReferencePrice &&
      showReferencePrice &&
      customShowReferencePrice ? (
      <ReferencePriceInfo
        bundle={salesOrderDetail.bundle}
        categoryList={categoryList}
        gradeList={gradeList}
        showPrice={
          s.enableReferencePrice &&
          showReferencePrice &&
          customShowReferencePrice
        }
        referencePrice={unitPrice}
        lineThrough={unitPrice !== null && unitPrice !== salesOrderDetail.price}
        showIcon={Util.isDefinedAndNotNull(salesOrderDetail.bundle)}
        size={ReferencePriceSize.Small}
        salesOrderId={salesOrderId}
        customerId={customerId}
      />
    ) : null;
  };
}

SalesOrderDetailListItem.defaultProps = {
  details: [],
  salesOrderDetail: null,
  orderType: null,
  showPrice: false,
  customShowReferencePrice: true,
  allowEditing: false,
  allowDeletion: false,
  categoryList: [],
  gradeList: [],
  onClick: (item) => {},
  onDelete: (item) => {},
  onUnitPriceChange: (item, priceTxt) => {},
  onDeductedQuantityChange: (item, deductedQuantityTxt) => {},
  onPaymentIconClick: null,
  onShipmentIconClick: null,
  salesOrderId: null,
  customerId: null,
  showBundlesWithSamePrefixes: false,
  bundlesWithSamePrefixes: [],
};

export default SalesOrderDetailListItem;
