import React from "react";
import { Boxs } from "saltui";
import { IAppliedPriceItemDTO } from "../../app/WebAPIClients";
import ListItem from "../ListItem";
import { IAppliedPriceItemViewModelForUI } from "../type";
import Util from "../Util";
const { HBox, Box } = Boxs;

export interface IAppliedPriceListItemProps {
    appliedPriceItem: IAppliedPriceItemDTO | IAppliedPriceItemViewModelForUI;
    onQuantityChange?: (
        quantity: number,
        appliedPriceItem:
            | IAppliedPriceItemDTO
            | IAppliedPriceItemViewModelForUI,
        e: React.FormEvent<HTMLInputElement>
    ) => void;
    onUnitPriceChange?: (
        unitPrice: number,
        appliedPriceItem:
            | IAppliedPriceItemDTO
            | IAppliedPriceItemViewModelForUI,
        e: React.FormEvent<HTMLInputElement>
    ) => void;
    onDelete?: (
        appliedPriceItem: IAppliedPriceItemDTO | IAppliedPriceItemViewModelForUI
    ) => void;
    onClick?: (
        appliedPriceItem: IAppliedPriceItemDTO | IAppliedPriceItemViewModelForUI
    ) => void;
    showPrice?: boolean;
    allowEditing?: boolean;
    allowDeletion?: boolean;
    onSelect?: (
        appliedPriceItem: IAppliedPriceItemDTO | IAppliedPriceItemViewModelForUI
    ) => void;
    allowSelection?: boolean;
    selected?: boolean;
}

export interface IAppliedPriceListItemState {
    expanded: boolean;
}

class AppliedPriceListItem extends React.Component<
    IAppliedPriceListItemProps,
    IAppliedPriceListItemState
> {
    public static defaultProps: IAppliedPriceListItemProps;

    public render() {
        const t = this;

        const {
            appliedPriceItem,
            showPrice,
            allowEditing,
            allowDeletion,
            allowSelection,
            selected
        } = t.props;

        const title = appliedPriceItem.name + " | " + appliedPriceItem.unit;
        let description = Util.isNotNullAndNotEmpty(appliedPriceItem.type)
            ? "类型：" + appliedPriceItem.type
            : "";

        description += Util.isNotNullAndNotEmpty(appliedPriceItem.notes)
            ? (Util.isNotNullAndNotEmpty(description) ? " | " : "") +
              "备注：" +
              appliedPriceItem.notes
            : "";

        const readOnly = !allowEditing;

        let extraInfo = null;

        if (showPrice && allowEditing) {
            extraInfo = (
                <HBox flex={1}>
                    <Box className="unitPriceArea unitPriceLabel">数量</Box>
                    <Box className="unitPriceArea">
                        <input
                            type="number"
                            min="0"
                            step="0.1"
                            name="quantity"
                            readOnly={readOnly}
                            onChange={this.onQuantityChange}
                            className={
                                readOnly
                                    ? "unitPriceTextBox readOnlyTextBox"
                                    : "unitPriceTextBox"
                            }
                            value={appliedPriceItem.quantity}
                        />
                    </Box>
                    <Box className="unitPriceArea deductedReasonLabel">
                        单价
                    </Box>
                    <Box className="unitPriceArea" flex={1}>
                        <input
                            type="number"
                            min="0"
                            step="0.1"
                            name="unitPrice"
                            readOnly={readOnly}
                            onChange={this.onUnitPriceChange}
                            className={
                                readOnly
                                    ? "unitPriceTextBox readOnlyTextBox"
                                    : "unitPriceTextBox"
                            }
                            value={appliedPriceItem.unitPrice}
                        />
                    </Box>
                    <Box className="unitPriceArea">
                        <p className="demo3-t-list-title">
                            <span className="unitPriceText">
                                ￥{Util.calculateSubTotal(
                                    appliedPriceItem.quantity,
                                    0,
                                    appliedPriceItem.unitPrice
                                )}
                            </span>
                        </p>
                    </Box>
                </HBox>
            );
        } else if (showPrice) {
            extraInfo = (
                <HBox flex={1}>
                    <Box className="unitPriceArea unitPriceLabel">数量</Box>
                    <Box className="unitPriceArea">
                        <span className="unitPriceText">
                            {appliedPriceItem.quantity}
                        </span>
                    </Box>
                    <Box className="unitPriceArea deductedReasonLabel">
                        单价
                    </Box>
                    <Box className="unitPriceArea" flex={1}>
                        <span className="unitPriceText">
                            ￥{appliedPriceItem.unitPrice}
                        </span>
                    </Box>
                    <Box className="unitPriceArea">
                        <p className="demo3-t-list-title">
                            <span className="unitPriceText">
                                ￥{appliedPriceItem.subtotal}
                            </span>
                        </p>
                    </Box>
                </HBox>
            );
        }

        const avatarTitle = appliedPriceItem.name;

        return (
            <ListItem
                avatarTitle={avatarTitle}
                title={title}
                description={description}
                extraInfo={extraInfo}
                allowDeletion={allowDeletion}
                onDelete={this.onDelete}
                onClick={this.onClick}
                allowSelection={allowSelection}
                selected={selected}
                onSelect={this.onSelect}
            />
        );
    }

    private onUnitPriceChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = this.getInputValue(e);
        this.props.onUnitPriceChange(value, this.props.appliedPriceItem, e);
    };

    private onQuantityChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = this.getInputValue(e);
        this.props.onQuantityChange(value, this.props.appliedPriceItem, e);
    };

    private onDelete = () => {
        this.props.onDelete(this.props.appliedPriceItem);
    };

    private onClick = () => {
        this.props.onClick(this.props.appliedPriceItem);
    };
    private onSelect = () => {
        this.props.onSelect(this.props.appliedPriceItem);
    };

    private getInputValue(e: React.FormEvent<HTMLInputElement>): number {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        if (typeof value !== "undefined" && value !== null) {
            return parseFloat(value);
        }
    }
}

AppliedPriceListItem.defaultProps = {
    appliedPriceItem: null,
    onQuantityChange: (quantity, appliedPriceItem, e): void => {},
    onUnitPriceChange: (unitPrice, appliedPriceItem, e): void => {},
    onDelete: (appliedPriceItem): void => {},
    onClick: (appliedPriceItem): void => {},
    onSelect: (appliedPriceItem): void => {},
    showPrice: false,
    allowEditing: false,
    allowDeletion: false,
    allowSelection: false,
    selected: false
};

export default AppliedPriceListItem;
