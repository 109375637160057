import * as React from "react";
import {
    IShippingOrderForListDTO,
    StoneArtifactType
} from "../app/WebAPIClients";
import Consts from "./Consts";
import StoneArtifactUtil from "./StoneArtifactUtil";
import { ISalesOrderDetailForUI } from "./type";
const { ShippingOrderStatus } = Consts;

class ShippingOrderUtil {
    // 获取装车单的状态显示内容
    public getSPOStatus(status: number) {
        const soStatusName = ShippingOrderStatus.getStatus(status).text;
        let className = "";
        switch (status) {
            case 10:
                className = "approvingSO";
                break;
            case 20:
                className = "normalSO";
                break;
            case 30:
                className = "normalSO";
                break;
            case 40:
                className = "normalSO";
                break;
            case 50:
                className = "cancelledWO";
                break;
        }

        return <span className={className}>{soStatusName}</span>;
    }

    public handleOnClick(item: IShippingOrderForListDTO, history: any) {
        const pathname = "/shippingorders/info/" + item.id;
        history.push(pathname);
    }

    // 根据订单类型和传入的itemName，获取装车类型
    public getShippingItemType(itemName: string, orderType: number): number {
        let shippingItemType = null;
        // todo: 如果以后添加了文化石或者马赛克的装车，需要修改下列语句
        // 如果销售订单类型是库存毛板或光板(orderType=30)或者无库存光板(orderType=40)或者无库存毛板(orderType=50)，则装车单类型为大板(itemType=20)
        // 如果销售订单类型是库存荒料(orderType=10)或者无库存荒料(orderType=20)，则装车单类型为荒料(itemType=10)
        // 如果销售订单类型是工程板(orderType=60)，则装车单类型为工程板(itemType=30)
        // 如果销售订单类型是销售荒料代加工(orderType=80)，若装车单中只装了大板，则装车单类型为大板(itemType=20)，若装车单中只装了荒料，则装车单类型为荒料(itemType=10)，若两则都有，则为荒料 | 大板

        switch (orderType) {
            case 10:
                shippingItemType = 10;
                break;
            case 30:
            case 40:
            case 50:
                shippingItemType = 20;
                break;
            case 80:
                if (itemName === "bundleId") {
                    shippingItemType = 20;
                } else if (itemName === "blockId") {
                    shippingItemType = 10;
                }
                break;
            case 60:
                shippingItemType = 30;
                break;
            default:
                return;
        }
        return shippingItemType;
    }

    public getItemsQuantityInfo(
        items: ISalesOrderDetailForUI[],
        type: number
    ): JSX.Element {
        let quantity = null;
        let deductedQuantity = null;
        items.forEach(d => {
            quantity = quantity + d.quantity;
            deductedQuantity = deductedQuantity + d.deductedQuantity;
        });
        quantity = quantity.toFixed(3);
        deductedQuantity = deductedQuantity.toFixed(3);
        const quantityUnit = this.getStoneArtifactQuantityUnit(type);
        const quantityDescription =
            deductedQuantity > 0 ? (
                <span>
                    {quantity} {quantityUnit} (扣：{deductedQuantity}
                    {quantityUnit})
                </span>
            ) : (
                <span>
                    {quantity} {quantityUnit}
                </span>
            );

        return quantityDescription;
    }

    public getShippingItemCount(shipItems: ISalesOrderDetailForUI[]): string {
        let countText = "";

        const blockShippingItems = StoneArtifactUtil.getBlockItems(shipItems);
        const bundleShippingItems = StoneArtifactUtil.getBundleItems(shipItems);
        const tpcShippingItems = StoneArtifactUtil.getTilePackingCaseItems(
            shipItems
        );
        if (blockShippingItems.length > 0) {
            countText += "荒料" + blockShippingItems.length + "颗";
        }
        if (bundleShippingItems.length > 0) {
            countText += " 大板" + bundleShippingItems.length + "扎";
        }

        if (tpcShippingItems.length > 0) {
            countText += " 工程板" + tpcShippingItems.length + "箱";
        }

        return countText;
    }

    public getShippingOrderType(shipItems: ISalesOrderDetailForUI[]): string {
        let text = "";
        if (shipItems.some(sp => sp.type === StoneArtifactType.Block)) {
            text = "荒料";
        }

        if (shipItems.some(sp => sp.type === StoneArtifactType.StoneBundle)) {
            text += " 大板";
        }

        if (shipItems.some(sp => sp.type === StoneArtifactType.Tile)) {
            text += " 工程板";
        }

        return text.trim();
    }

    public getStoneArtifactQuantityUnit(type: number): string {
        if (!type) {
            return;
        }

        let quantityUnit = "";

        switch (type) {
            case StoneArtifactType.Block:
                quantityUnit = "立方";
                break;
            case StoneArtifactType.StoneBundle:
            case StoneArtifactType.Tile:
                quantityUnit = "平方";
                break;
            default:
                return;
        }

        return quantityUnit;
    }

    public getStoneArtifactUnit(type: number): string {
        if (!type) {
            return;
        }

        let unit = "";

        switch (type) {
            case StoneArtifactType.Block:
                unit = "颗";
                break;
            case StoneArtifactType.StoneBundle:
                unit = "扎";
                break;
            case StoneArtifactType.Tile:
                unit = "箱";
                break;
            default:
                return;
        }

        return unit;
    }

    public getShippingItemInfoForList(
        item,
        categoryList,
        gradeList,
        showBundleGrades: boolean = true
    ) {
        const listItem = StoneArtifactUtil.getStoneArtifactInfoForList(
            item,
            categoryList,
            gradeList,
            showBundleGrades
        );

        if (item && item.salesOrderNumber) {
            listItem.text = (
                <div>
                    <div>{listItem.text}</div>
                    {item.salesOrderNumber}
                </div>
            );
        }

        return listItem;
    }
}

export default new ShippingOrderUtil();
