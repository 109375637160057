import * as React from "react";
import {
    IPaymentOrderDTO,
    IPaymentOrderForListDTO,
    ISalesOrderDetailDTO,
    PaymentOrderStatus,
    StoneArtifactType
} from "../app/WebAPIClients";
import Consts from "./Consts";
import ListItem from "./ListItem";
const { PaymentOrderStatusOptions } = Consts;
import { Group, NumberInfo } from "saltui";
import Perm from "../app/Perm";
import ESHeader from "./ESHeader";
import ESNoticeBar from "./ESNoticeBar";
import PermCtl from "./PermCtrl";
import SalesOrderUtil from "./SalesOrderUtil";
import Settings from "./SettingInfo";
import SettingsDefinition from "./SettingsDefinition";
import StoneArtifactUtil from "./StoneArtifactUtil";
import Util from "./Util";
const { NumberItem } = NumberInfo;

class PaymentOrderUtil {
    // 获取结算单的状态显示内容
    public getStatusJSX(status: PaymentOrderStatus) {
        const soStatusName = this.getStatusName(status);
        let className = "";
        switch (status) {
            case 10:
                className = "approvingSO";
                break;
            case 20:
                className = "waitingSettlement";
                break;
            case 40:
                className = "normalSO";
                break;
            case 30:
            case 100:
                className = "cancelledWO";
                break;
        }

        return <span className={className}>{soStatusName}</span>;
    }

    public getPOListItem(
        po: IPaymentOrderForListDTO,
        onClick: (item: IPaymentOrderForListDTO) => void
    ): JSX.Element {
        const title = this.getPOListItemTitle(po);
        const description = this.getPOListItemDescription(po);
        const showBadge =
            po.isUpdated &&
            ![
                PaymentOrderStatus.Cancelled,
                PaymentOrderStatus.PaymentReceived
            ].includes(po.status);
        const badgeText = showBadge ? "更新" : null;

        return (
            <ListItem
                key={po.id}
                item={po}
                avatarTitle={po.orderNumber}
                title={title}
                description={description}
                onClick={onClick}
                showBadge={showBadge}
                badgeText={badgeText}
                badgeCorner={"rb"}
            />
        );
    }

    public getPOListItemTitle(po: IPaymentOrderForListDTO): JSX.Element {
        return (
            <span>
                {po.orderNumber}
                {" | "}
                {this.getStatusJSX(po.status)}
            </span>
        );
    }

    public getPOListItemDescription(po: IPaymentOrderForListDTO): JSX.Element {
        return (
            <span>
                {"客户："}
                {po.customerName}
                {" | "}
                {po.itemsCount}
                {"个结算项"}
                {" | "}
                {"总金额："}
                {po.totalAmount}
            </span>
        );
    }

    public getPOStatusText(po: IPaymentOrderDTO): string {
        if (!po) {
            return "";
        }

        return this.getStatusName(po.status);
    }

    public getSelectedStatus(selectedStatus: number): number {
        if (selectedStatus === 0) {
            return null;
        }
        return selectedStatus;
    }

    public showPOUpdatedNotes(
        po: IPaymentOrderDTO | IPaymentOrderForListDTO,
        text: string
    ) {
        const elementJSX =
            po.isUpdated &&
            ![
                PaymentOrderStatus.Cancelled,
                PaymentOrderStatus.PaymentReceived
            ].includes(po.status) ? (
                <ESNoticeBar
                    className="noticeMessageSty"
                    message={text + po.previousTotalAmount}
                />
            ) : null;

        return elementJSX;
    }

    /**
     * ShowSODPriceAndProfitInfo
     */
    public ShowSODPriceAndProfitInfo(
        details: ISalesOrderDetailDTO[],
        enableReferencePrice: boolean
    ) {
        const canViewAmount = PermCtl.isAuthorized(Perm.SO_P_R);

        const detailsInBlock = StoneArtifactUtil.getBlockItems(details);
        const detailsInBundle = StoneArtifactUtil.getBundleItems(details);
        // 大板销售均价
        const averageSoldPriceForBundle = this.getAverageSoldPrice(
            details,
            StoneArtifactType.StoneBundle
        );
        // 大板参考价均价
        const averageReferencePriceForBundle = this.getAverageReferencePrice(
            details,
            StoneArtifactType.StoneBundle
        );

        // 荒料销售均价
        const averageSoldPriceForBlock = this.getAverageSoldPrice(
            details,
            StoneArtifactType.Block
        );

        // 荒料参考价均价
        const averageReferencePriceForBlock = this.getAverageReferencePrice(
            details,
            StoneArtifactType.Block
        );

        const bundleDetailsNoRPCount = this.anyDetalesNoReferencePrice(
            details,
            StoneArtifactType.StoneBundle
        );
        const blockDetailsNoRPCount = this.anyDetalesNoReferencePrice(
            details,
            StoneArtifactType.Block
        );

        const showBundlePriceAndProfitInfo =
            enableReferencePrice && canViewAmount && detailsInBundle.length > 0;

        const showBlockPriceAndProfitInfo =
            enableReferencePrice && canViewAmount && detailsInBlock.length > 0;

        if (!showBlockPriceAndProfitInfo && !showBundlePriceAndProfitInfo) {
            return null;
        }

        return (
            <Group>
                <ESHeader label={"均价信息"} />
                <Group.List lineIndent={15}>
                    {showBundlePriceAndProfitInfo ? (
                        <div className="number-info-wrap">
                            {bundleDetailsNoRPCount > 0 ? (
                                <NumberInfo
                                    label={
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "16px"
                                            }}
                                        >
                                            {`有${bundleDetailsNoRPCount}扎大板无参考价，不参与计算均价和差额`}
                                        </span>
                                    }
                                />
                            ) : null}
                            <NumberInfo label="大板销售均价（参考均价）">
                                <NumberItem
                                    groupDigits={3}
                                    spliter=","
                                    number={averageSoldPriceForBundle}
                                    unit="元"
                                />
                                <NumberItem
                                    number={averageReferencePriceForBundle}
                                    unit="元"
                                    secondary={true}
                                    positiveColor="#097BF7"
                                />
                            </NumberInfo>
                            <NumberInfo label="与参考总价差额">
                                <NumberItem
                                    groupDigits={3}
                                    spliter=","
                                    number={this.getProfitAndLoss(
                                        details,
                                        StoneArtifactType.StoneBundle
                                    )}
                                    unit="元"
                                    positiveColor="green"
                                    negativeColor="red"
                                    showSign={true}
                                />
                            </NumberInfo>
                        </div>
                    ) : null}
                    {showBlockPriceAndProfitInfo ? (
                        <div className="number-info-wrap">
                            {blockDetailsNoRPCount > 0 ? (
                                <NumberInfo
                                    label={
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "16px"
                                            }}
                                        >
                                            {`有${blockDetailsNoRPCount}颗荒料无参考价，不参与计算均价和差额`}
                                        </span>
                                    }
                                />
                            ) : null}
                            <NumberInfo label="荒料销售均价（参考均价）">
                                <NumberItem
                                    groupDigits={3}
                                    spliter=","
                                    number={averageSoldPriceForBlock}
                                    unit="元"
                                />
                                <NumberItem
                                    number={averageReferencePriceForBlock}
                                    unit="元"
                                    secondary={true}
                                    positiveColor="#097BF7"
                                />
                            </NumberInfo>
                            <NumberInfo label="与参考总价差额">
                                <NumberItem
                                    groupDigits={3}
                                    spliter=","
                                    number={this.getProfitAndLoss(
                                        details,
                                        StoneArtifactType.Block
                                    )}
                                    unit="元"
                                    positiveColor="green"
                                    negativeColor="red"
                                    showSign={true}
                                />
                            </NumberInfo>
                        </div>
                    ) : null}
                </Group.List>
            </Group>
        );
    }

    public getAverageSoldPrice(
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let subTotal = 0;
        let quantity = 0;

        if (details && details.length > 0) {
            details.forEach((d, index) => {
                const referencePrice = SalesOrderUtil.getPriceInfo(d);
                if (
                    d.type === type &&
                    Util.isDefinedAndNotNull(referencePrice) &&
                    referencePrice > 0
                ) {
                    subTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        d.price
                    );
                    quantity += d.quantity - d.deductedQuantity;
                }
            });
        }

        const averagePrice = quantity === 0 ? 0 : subTotal / quantity;

        return Util.round(
            Util.isNotNullAndNotNaN(averagePrice) ? averagePrice : 0,
            2
        );
    }

    public getAverageReferencePrice(
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let subTotal = 0;
        let quantity = 0;

        if (details && details.length > 0) {
            details.forEach((d, index) => {
                const referencePrice = SalesOrderUtil.getPriceInfo(d);
                if (
                    d.type === type &&
                    Util.isDefinedAndNotNull(referencePrice) &&
                    referencePrice > 0
                ) {
                    quantity += d.quantity - d.deductedQuantity;
                    subTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        referencePrice
                    );
                }
            });
        }

        const averageReferencePrice = quantity === 0 ? 0 : subTotal / quantity;

        return Util.round(
            Util.isNotNullAndNotNaN(averageReferencePrice)
                ? averageReferencePrice
                : 0,
            2
        );
    }

    public getProfitAndLoss(
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let markSubTotal = 0;
        let soldSubTotal = 0;

        if (details && details.length > 0) {
            details.forEach((d, index) => {
                const referencePrice = SalesOrderUtil.getPriceInfo(d);
                if (
                    d.type === type &&
                    Util.isDefinedAndNotNull(referencePrice) &&
                    referencePrice > 0
                ) {
                    soldSubTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        d.price
                    );
                    markSubTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        referencePrice
                    );
                }
            });
        }

        const profitAndLoss = soldSubTotal - markSubTotal;

        return Util.round(profitAndLoss, 2);
    }

    private anyDetalesNoReferencePrice(
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let noRPDetailCount = 0;

        if (details && details.length > 0) {
            details.forEach((d, index) => {
                const referencePrice = SalesOrderUtil.getPriceInfo(d);
                if (
                    d.type === type &&
                    !(
                        Util.isDefinedAndNotNull(referencePrice) &&
                        referencePrice > 0
                    )
                ) {
                    noRPDetailCount = noRPDetailCount + 1;
                }
            });
        }

        return noRPDetailCount;
    }

    private getStatusName(status: PaymentOrderStatus) {
        const enableApprovalPO = Settings.getBoolValue(
            SettingsDefinition.EnableApprovalPOKey
        );
        return enableApprovalPO === true
            ? PaymentOrderStatusOptions.getStatusWithApproval(status).text
            : PaymentOrderStatusOptions.getStatus(status).text;
    }
}

export default new PaymentOrderUtil();
